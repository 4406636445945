import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import trim from 'lodash/trim';
import mapKeys from 'lodash/mapKeys';

import useTracking from 'components/Globals/Analytics';
import EntityCard from 'components/Globals/EntityCard';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import LinkButton, { PrimaryButton } from 'components/uiLibrary/LinkButton';
import Loader from 'components/uiLibrary/Loader';
import { SUB_COMPONENTS, CATEGORIES } from 'components/Globals/Analytics/constants';
import useInputSearchTracking from 'components/Globals/Analytics/hooks/useInputSearchTracking';
import AutocompleteDropDown from 'components/uiLibrary/FormInputs/AutocompleteDropDown';
import CreateNewEntityDialog from 'components/Globals/CreateNewEntityDialog';

import {
  SEARCH_ENTITY_TYPE_MAP,
  ORGANIZATION_TYPE_IDS,
  ENTITY_TYPE,
  SEARCH_ENTITY_MAP,
  TP,
  PROFESSION_IDS,
} from 'constants/index';

import { useVisitsHistory } from 'utils/search/visitsHistory';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useUniversalSearch } from 'utils/search';
import useAppContext from 'utils/hooks/useAppContext';
import useOnKeypress, { keyCodes } from 'utils/hooks/useOnKeypress';
import { useTranslation } from 'src/i18n';

import classes from './EntityProfileSelector.module.scss';

const DEFAULT_ENTITY_TYPES = [ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION];
const EMPTY_ARRAY = [];
const ADD_NEW_ENTITY_PROFILE_ID = 'ADD_NEW_ENTITY_PROFILE';
const ADD_NEW_ENTITY_PROFILE_OPTION = { id: ADD_NEW_ENTITY_PROFILE_ID };

const LOGIN_ACCESS_FULL_HISTORY_ID = 'LOGIN_ACCESS_FULL_HISTORY';

const CHANGE_REASONS = {
  INPUT: 'input',
  CREATE: 'createOption',
};

const getMappedDataForTracking = results =>
  results?.map((item, index) => {
    const { id, entity, entityType } = item || {};
    return {
      entityId: id,
      entityName: entity,
      entityType,
      searchResultPosition: index,
    };
  });

const useSearchResults = ({
  inputValue,
  allowedEntityTypes,
  allowedOrganizationTypes,
  excludedOrganizationTypes,
  excludeIds,
  allowEntityCreation,
  filters,
  onSuccess,
  limit = 10,
}) => {
  const { data, isFetching, isFetched, refetch } = useUniversalSearch(
    {
      query: inputValue,
      entity_type: allowedEntityTypes?.map(entityType => SEARCH_ENTITY_MAP[entityType])?.join(','),
      organization_type_id: allowedOrganizationTypes,
      exclude_organization_type_id: excludedOrganizationTypes,
      exclude_ids: excludeIds,
      limit,
      page: 1,
      ...(allowEntityCreation && { as_edit: true }),
      ...filters,
    },
    {
      onSuccess,
      enabled: false,
      keepPreviousData: true,
    },
  );

  return {
    data,
    isFetching,
    isFetched,
    refetch,
  };
};

const EntityProfileSelector = ({
  allowedEntityTypes = DEFAULT_ENTITY_TYPES,
  allowedOrganizationTypes = EMPTY_ARRAY,
  excludedOrganizationTypes = EMPTY_ARRAY,
  onChange,
  onSearch,
  searchQuery = '',
  allowEntityCreation = false,
  trackingData,
  value,
  label,
  fullWidth = false,
  excludeIds = EMPTY_ARRAY,
  showRecentlyVisited = false,
  filters,
  boostBy,
  isComposer,
  isDisabled,
  className,
  disablePortal = false,
  onToggleEntityCreation,
}) => {
  const selectedInputValue = searchQuery || value?.entity?.entity || value?.entity?.name || '';
  const [inputValue, setInputValue] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [showAddNewEntityProfileModal, setShowAddNewEntityProfileModal] = useState(false);
  const recentlyVisitedHistory = useVisitsHistory();
  const { isLoggedIn } = useAppContext();
  const { setIsLoginDialog } = useDialogs();
  const profession = isComposer ? { id: PROFESSION_IDS.COMPOSER } : null;
  const isVenueField =
    allowedOrganizationTypes?.length === 1 && allowedOrganizationTypes[0] === ORGANIZATION_TYPE_IDS.VENUE;

  useEffect(() => {
    if (onToggleEntityCreation) {
      onToggleEntityCreation(showAddNewEntityProfileModal);
    }
  }, [onToggleEntityCreation, showAddNewEntityProfileModal]);

  const track = useTracking();
  const { t } = useTranslation('NS_APP_GLOBALS');

  const { sessionId, onFocus: onInputFocusForAnalytics, onBlur: onInputBlurForAnalytics } = useInputSearchTracking(
    trackingData,
  );

  const trackData = response => {
    track.search({
      ...trackingData,
      searchQuery: inputValue,
      category: CATEGORIES.SEARCH,
      meta: {
        ...trackingData?.meta,
        searchSessionId: sessionId,
        results: getMappedDataForTracking(response?.data || EMPTY_ARRAY),
      },
    });
  };

  const searchFilters = useMemo(
    () => ({
      ...(filters || {}),
      ...(isComposer && { has_creations: true, creator_profession: 'composer' }),
      ...(boostBy && mapKeys(boostBy, (value, key) => `boost_${key}`)),
    }),
    [filters, isComposer],
  );

  const { otherGroupName, showOtherPossibleMatches } = useMemo(() => {
    let groupName = `${TP}.FN_NON_COMPOSER_ENTITES_LABEL`;

    if (allowedEntityTypes.length === 1) {
      if (allowedEntityTypes[0] === ENTITY_TYPE.ORGANIZATION) {
        groupName = t(`${TP}.FN_OTHER_ORGANISATIONS`);
      }

      if (allowedEntityTypes[0] === ENTITY_TYPE.ARTIST) {
        groupName = t(`${TP}.FN_OTHER_ARTISTS`);
      }

      if (allowedEntityTypes[0] === ENTITY_TYPE.MANAGER) {
        groupName = t(`${TP}.FN_OTHER_AGENCIES`);
      }
    }

    return {
      showOtherPossibleMatches: isComposer || Object.keys(filters || {}).length > 0,
      otherGroupName: t(groupName),
    };
  }, [allowedEntityTypes, isComposer, filters, t]);

  const { data, isFetching, isFetched, refetch: fetchSearchResults } = useSearchResults({
    inputValue,
    allowedEntityTypes,
    allowedOrganizationTypes,
    excludedOrganizationTypes,
    excludeIds,
    allowEntityCreation,
    filters: searchFilters,
    onSuccess: trackData,
  });

  const { data: allEntities, refetch: fetchAllEntities } = useSearchResults({
    inputValue,
    allowedEntityTypes,
    allowedOrganizationTypes,
    excludedOrganizationTypes,
    excludeIds,
    allowEntityCreation,
    onSuccess: trackData,
    limit: 20,
  });

  const debouncedFetchSearchResults = useMemo(() => debounce(fetchSearchResults, 300), [fetchSearchResults]);
  const debouncedFetchAllResults = useMemo(() => debounce(fetchAllEntities, 300), [fetchAllEntities]);

  useEffect(() => {
    if (inputValue) {
      debouncedFetchSearchResults();

      if (showOtherPossibleMatches) {
        debouncedFetchAllResults();
      }
    }
  }, [showOtherPossibleMatches, debouncedFetchSearchResults, debouncedFetchAllResults, inputValue]);

  const secondaryEntities = useMemo(() => {
    if (!showOtherPossibleMatches) {
      return EMPTY_ARRAY;
    }

    const composerEntities = data?.data || EMPTY_ARRAY;
    const allEntitiesData = allEntities?.data || EMPTY_ARRAY;

    return allEntitiesData?.reduce((acc, entity) => {
      if (acc.length >= 10) {
        return acc;
      }

      if (
        !composerEntities.some(
          composerEntity => composerEntity.id === entity.id && composerEntity.entityType === entity.entityType,
        )
      ) {
        acc.push({
          ...entity,
          group: otherGroupName,
        });
      }

      return acc;
    }, []);
  }, [allEntities?.data, data?.data, showOtherPossibleMatches, otherGroupName]);

  const handleChange = useCallback(
    option => {
      if (onChange) {
        onChange(
          option
            ? {
              entity: option,
              entityType: option.entityType,
            }
            : {},
        );
      }
    },
    [onChange],
  );

  const handleResultClick = useCallback(
    (option, reason) => {
      setIsFocused(false);

      if (reason === CHANGE_REASONS.CREATE) {
        if (allowEntityCreation) {
          setShowAddNewEntityProfileModal(true);
        } else if (onSearch) {
          onSearch(trim(option));
        }
      } else {
        setInputValue(null);
        handleChange(option);
      }
    },
    [allowEntityCreation, handleChange, onSearch],
  );

  const handleAddNewEntityProfile = () => {
    setIsFocused(false);
    setShowAddNewEntityProfileModal(true);
    // NOTE: tracking it here instead of in LinkButton, since we want to pass results, and we want to calculate it only on click
    track.click({
      ...trackingData,
      subComponent: SUB_COMPONENTS.ADD_CTA,
      meta: {
        results: getMappedDataForTracking(data?.data || EMPTY_ARRAY),
      },
    });
  };

  const { placeholderText, ctaText } = useMemo(() => {
    let placeholder = t(`${TP}.FN_SEARCH_ARTIST_ORGANIZATION_BY_NAME`);
    let ctaLabel = t(`${TP}.AS_ADD_NEW`);

    if (allowedEntityTypes?.length === 1) {
      if (allowedEntityTypes[0] === ENTITY_TYPE.ORGANIZATION) {
        placeholder = t(`${TP}.FN_SEARCH_ORGANIZATIONS`);
        ctaLabel = t(`${TP}.FN_ADD_NEW_ORGANIZATION`);
        if (isVenueField) {
          placeholder = t(`${TP}.FN_SEARCHVENUES`);
          ctaLabel = t(`${TP}.FN_ADD_NEW_VENUE`);
        }
      }

      if (allowedEntityTypes[0] === ENTITY_TYPE.ARTIST) {
        placeholder = t(`${TP}.m_SEARCHFORARTIST`);
        ctaLabel = t(`${TP}.FN_ADD_NEW_INDIVIDUAL_PERSON`);
      }

      if (allowedEntityTypes[0] === ENTITY_TYPE.MANAGER) {
        placeholder = t(`${TP}.FN_AGENCY_SEARCH_PLACEHOLDER`);
        ctaLabel = t(`${TP}.FN_ADD_AGENCY`);
      }
    }

    return {
      placeholderText: placeholder,
      ctaText: ctaLabel,
    };
  }, [t, isVenueField, allowedEntityTypes]);

  const recentlyVisitedOptions = useMemo(() => {
    if (!showRecentlyVisited) {
      return EMPTY_ARRAY;
    }

    const groupName = t(`${TP}.SRCH_RECENTLY_VISITED`);
    return recentlyVisitedHistory.reduce((acc, item) => {
      const entityType = SEARCH_ENTITY_TYPE_MAP[item.type];

      if (allowedEntityTypes.includes(entityType)) {
        acc.push({
          ...item?.entity,
          entityType: SEARCH_ENTITY_TYPE_MAP[item.type],
          group: groupName,
        });
      }

      return acc;
    }, []);
  }, [t, showRecentlyVisited, recentlyVisitedHistory, allowedEntityTypes]);

  const options = useMemo(() => {
    if (isFocused) {
      if (inputValue?.length) {
        return [
          ...(data?.data || EMPTY_ARRAY),
          ...secondaryEntities,
          ...(allowEntityCreation ? [ADD_NEW_ENTITY_PROFILE_OPTION] : EMPTY_ARRAY),
        ];
      }

      if (recentlyVisitedOptions?.length) {
        const shouldLogin = !isLoggedIn && recentlyVisitedOptions?.length >= 5;

        return [
          ...recentlyVisitedOptions.slice(0, 5),
          ...(shouldLogin
            ? [
              {
                id: LOGIN_ACCESS_FULL_HISTORY_ID,
                group: recentlyVisitedOptions?.[0]?.group,
              },
            ]
            : EMPTY_ARRAY),
        ];
      }

      return EMPTY_ARRAY;
    }

    return EMPTY_ARRAY;
  }, [
    secondaryEntities,
    allowEntityCreation,
    data?.data,
    inputValue?.length,
    isFocused,
    isLoggedIn,
    recentlyVisitedOptions,
  ]);

  const onInputChange = (e, text, reason) => {
    if (reason === CHANGE_REASONS.INPUT) {
      setInputValue(text);
    }
  };

  const endAdornment = useMemo(() => {
    if (isFetching) {
      return <Loader small color="#0067c5" />;
    }

    if (isDisabled) {
      return null;
    }

    if (selectedInputValue) {
      return <SpriteIcon icon="close" size={20} onClick={() => handleResultClick()} />;
    }

    return (
      <SpriteIcon
        icon="search"
        size={20}
        {...(inputValue && { onClick: () => handleResultClick(inputValue, CHANGE_REASONS.CREATE) })}
      />
    );
  }, [isFetching, isDisabled, handleResultClick, selectedInputValue, inputValue]);

  const onEnterKeyPress = useCallback(() => {
    if (isFocused) {
      handleResultClick(inputValue, CHANGE_REASONS.CREATE);
    }
  }, [isFocused, handleResultClick, inputValue]);

  useOnKeypress(keyCodes.ENTER, onEnterKeyPress);

  return (
    <>
      <AutocompleteDropDown
        label={label}
        className={className}
        disabled={isDisabled}
        inputValue={inputValue ?? selectedInputValue}
        open={isFocused && (inputValue?.length > 2 || options?.length > 0)}
        fullWidth={fullWidth}
        options={options}
        groupBy={option => option.group}
        onInputChange={onInputChange}
        onOpen={() => {
          setIsFocused(true);
          onInputFocusForAnalytics();
        }}
        onChange={(e, inputVal, reason) => handleResultClick(inputVal, reason)}
        InputProps={{
          endAdornment,
          onBlur: () => {
            onInputBlurForAnalytics();
            setIsFocused(false);
            setInputValue(null);
          },
        }}
        textFieldProps={{ placeholder: placeholderText }}
        renderMenuListItem={(props, item) => {
          if (!item || item?.noOption) {
            return null;
          }

          if (item.id === ADD_NEW_ENTITY_PROFILE_ID) {
            if (!isFetched) {
              return null;
            }

            return (
              <li className={classes.addNewEntity} key={item.id}>
                <Typography type="bold">
                  {options?.length > 1 ? t(`${TP}.FN_NO_RELEVANT_RESULTS_FOUND`) : t(`${TP}.NO_RESULTS`)}
                </Typography>
                <PrimaryButton shape="rectangle" size="large" onClick={handleAddNewEntityProfile} skipTracking>
                  {ctaText}
                </PrimaryButton>
              </li>
            );
          }

          if (item.id === LOGIN_ACCESS_FULL_HISTORY_ID) {
            return (
              <li className={classes.loginItem}>
                <LinkButton
                  variant="text"
                  onClick={() => setIsLoginDialog({ isOpen: true })}
                  rightIcon={<SpriteIcon icon="chevron_right" size={20} />}
                >
                  <Typography color="link" size={14}>
                    {t(`${TP}.FN_VIEW_MORE`)} ({t(`${TP}.m_LOGIN`)})
                  </Typography>
                </LinkButton>
              </li>
            );
          }

          const { entityType } = item;
          const entity = {
            ...item,
            name: item.entity || item.name,
          };
          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <li className={classes.listItem} onClick={() => handleResultClick(entity)} key={item.id}>
              <EntityCard
                entityType={entityType}
                entity={entity}
                typographyProps={{
                  name: {
                    weight: 'medium',
                  },
                  subtext: {
                    truncate: true,
                  },
                }}
                styles={{
                  title: classes.optionName,
                  subtext: classes.optionSubtext,
                }}
                isLinkShallow={false}
                withoutLink
                trackingData={trackingData}
              />
            </li>
          );
        }}
        freeSolo={false}
        disablePortal={disablePortal}
        selectOnFocus={false}
      />
      <CreateNewEntityDialog
        isOpen={showAddNewEntityProfileModal}
        query={inputValue}
        profession={profession}
        allowedEntityTypes={allowedEntityTypes}
        onSave={({ entity, entityType }) => {
          if (onChange) {
            onChange({
              entity,
              entityType,
            });
          }
          setShowAddNewEntityProfileModal(false);
        }}
        onClose={() => setShowAddNewEntityProfileModal(false)}
        organizationType={
          allowedOrganizationTypes?.length === 1 ? allowedOrganizationTypes[0] : filters?.organization_type_id
        }
      />
    </>
  );
};

EntityProfileSelector.propTypes = {
  allowedEntityTypes: PropTypes.arrayOf(
    PropTypes.oneOf([ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION, ENTITY_TYPE.MANAGER]),
  ),
  allowedOrganizationTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ORGANIZATION_TYPE_IDS))),
  onChange: PropTypes.func,
  value: PropTypes.shape({
    entity: PropTypes.object,
    entityType: PropTypes.oneOf([ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION, ENTITY_TYPE.MANAGER]),
  }),
  allowEntityCreation: PropTypes.bool,
  fullWidth: PropTypes.bool,
  excludeIds: PropTypes.array,
  className: PropTypes.string,
};

export default EntityProfileSelector;
