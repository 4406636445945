import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'src/i18n';
import classes from './RadioGroup.module.scss';

export const Radio = ({ disabled, label, value, checked, onChange, styles, onClick, ...props }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  return (
    <div
      className={classnames(classes.radio, {
        [classes.disabled]: disabled,
        [styles?.radio]: !!styles?.radio,
      })}
    >
      <label>
        <span className={classnames(classes.radioWrapper, { [styles?.radioWrapper]: !!styles?.radioWrapper })}>
          <input
            className={classnames(classes.radioInput, { [styles?.radioInput]: !!styles?.radioInput })}
            type="radio"
            value={value}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            onClick={onClick}
            {...props}
          />
        </span>

        {typeof label === 'string' ? (
          <span
            className={classnames(
              classes.radioLabel,
              { [classes.activeLabel]: checked },
              { [styles?.radioLabel]: !!styles?.radioLabel },
              { [styles?.hoverLabel]: !!styles?.hoverLabel },
              { [styles?.radioLabelChangePlan]: !!styles?.radioLabelChangePlan },
            )}
          >
            {t(label)}
          </span>
        ) : (
          label
        )}
      </label>
    </div>
  );
};

Radio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.string]).isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const RadioGroup = ({ disabled, options, value, onChange, inline, styles }) => {
  return (
    <div className={classnames({ [classes.inline]: inline, [styles?.radioGroup]: !!styles?.radioGroup })}>
      {options.map(option => (
        <Radio
          key={option.label}
          label={option.label}
          value={option.value}
          checked={value === option.value}
          onChange={() => onChange(option.value)}
          disabled={disabled || option.disabled}
          styles={styles}
        />
      ))}
    </div>
  );
};

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
};
export default RadioGroup;
